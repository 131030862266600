import * as React from 'react';
import { DynamoDBClient } from '@aws-sdk/client-dynamodb';
import { accountsHelper, CONTROLPLANE_DDB_NAME_PART } from '../utils/accountsUtils';
import { getRoleArn } from '../utils/gsRoleUtils';
import { buildTemporaryCredentialProvider } from '../clients/gsocCredentialsProvider';
import { useAppSettings } from './AppSettingsContext';
import { useGSOCReadOnlyCredentials } from './GSOCApiContext';
import { useUsername } from './MidwayContext';

export const LicenseExpiryDDBClientContext = React.createContext<DynamoDBClient | null>(null);

/**
 * S3Client context.
 *
 * Used to create and provide a, S3Client to the application.
 *
 * @param children Nested components
 * @returns
 */
export function LicenseExpiryDDBClientProvider(props: { children: React.ReactNode }) {
  const { region, stage } = useAppSettings();
  console.log(region);
  const username = useUsername();
  const gsocReadonlyCredentials = useGSOCReadOnlyCredentials();
  const globalTableRegion = 'us-west-2';

  const licenseExpiryDDBClient = React.useMemo(() => {
    const controlPlaneAccount = accountsHelper.getAccount(CONTROLPLANE_DDB_NAME_PART, stage, region);
    if (!controlPlaneAccount) {
      throw new Error(`No global ddb account found for stage ${stage} and region ${region}.`);
    }
    const controlPlaneAccountId = controlPlaneAccount.account_id;
    const roleArn = getRoleArn(controlPlaneAccountId, 'ReadOnly');
    return new DynamoDBClient({
      credentials: buildTemporaryCredentialProvider({
        roleArn,
        username,
        masterCredentials: {
          accessKeyId: gsocReadonlyCredentials.accessKeyId,
          secretAccessKey: gsocReadonlyCredentials.secretAccessKey,
          sessionToken: gsocReadonlyCredentials.sessionToken,
          expiration: gsocReadonlyCredentials.expiration,
        },
      }),
      region: globalTableRegion,
    });
  }, [gsocReadonlyCredentials, globalTableRegion, stage, username]);

  return (
    <LicenseExpiryDDBClientContext.Provider value={licenseExpiryDDBClient}>
      {props.children}
    </LicenseExpiryDDBClientContext.Provider>
  );
}

/** Get the DDB Client */
export function useLicenseExpiryDDBClient(): DynamoDBClient {
  const context = React.useContext(LicenseExpiryDDBClientContext);
  if (context === null) {
    throw new Error('useS3Client must be used within a AnalyticsAccountS3ClientProvider');
  }
  return context;
}
