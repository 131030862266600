import { Alert } from '@amzn/awsui-components-react';

/** Generic error display */
export function ErrorDisplay() {
  return (
    <Alert type='error'>
      <h3>Oops something went wrong, we&apos;re working on fixing this bug</h3>
    </Alert>
  );
}
