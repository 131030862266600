import { RumMidwayCredentialsProvider } from '@amzn/rum-midway-credentials-provider';
import type { AwsCredentialIdentityProvider } from '@aws-sdk/types';
import { AwsRum } from 'aws-rum-web';

const APPLICATION_VERSION = '1.0.0';

/** Create our RUM client */
export function createRumClient(props: { user: string; applicationId: string; region: string; roleArn: string }) {
  const rumClient = new AwsRum(props.applicationId, APPLICATION_VERSION, props.region, {
    sessionSampleRate: 1,
    endpoint: `https://dataplane.rum.${props.region}.amazonaws.com`,
    telemetries: ['errors', 'http', 'performance'],
    allowCookies: true,
    enableXRay: true,
    sessionAttributes: {
      // WARNING: This field is being used to track internal ops console usage
      // and identify users when investigating errors or just know overall usage in GSOC
      // This should not be moved to anything involving external customers without removing
      // this additional session attribute.
      user: props.user,
    },
  });

  const mwCredProvider: AwsCredentialIdentityProvider = new RumMidwayCredentialsProvider(
    props.region,
    window.location.origin,
    props.roleArn,
  ).ChainAnonymousCredentialsProvider;

  rumClient.setAwsCredentials(mwCredProvider);

  return rumClient;
}
