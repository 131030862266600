import * as React from 'react';

import type { SideNavigationProps } from '@amzn/awsui-components-react';
import { SideNavigation } from '@amzn/awsui-components-react';
import { useHistory } from 'react-router-dom';
import { BREADCRUMBS } from '../pages/pages';

export function NavigationSidebar() {
  const history = useHistory();

  return (
    <SideNavigation
      items={NAVIGATION_ITEMS}
      onFollow={React.useCallback(
        (ev: CustomEvent<SideNavigationProps.FollowDetail>) => {
          ev.preventDefault();
          if (ev.detail.href) {
            // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
            history.push(ev.detail.href);
          }
        },
        [history],
      )}
      activeHref={history.location.pathname}
    />
  );
}

const NAVIGATION_ITEMS: readonly SideNavigationProps.Item[] = [
  {
    type: 'section',
    text: 'Home',
    items: [
      { type: 'link', ...BREADCRUMBS.Home },
      { type: 'link', ...BREADCRUMBS.Oncall },
    ],
  },
  {
    type: 'section',
    text: 'Authorization',
    items: [
      {
        type: 'link',
        ...BREADCRUMBS.Authorization,
      },
    ],
  },
  {
    type: 'section',
    text: 'Onboarding',
    items: [
      {
        type: 'link',
        ...BREADCRUMBS.LicenseExpiry,
      },
    ],
  },
  {
    type: 'section',
    text: 'Contacts',
    items: [
      {
        type: 'link',
        ...BREADCRUMBS.ListContacts,
      },
      {
        type: 'link',
        ...BREADCRUMBS.ContactSearch,
      },
    ],
  },
  // Commenting out GS site visualization while it's using mocked data  - https://sim.amazon.com/issues/AWS-GSAAS-CX-1368
  // {
  //   type: "section",
  //   text: "Ground Station Sites",
  //   items: [
  //     {
  //       type: "link",
  //       text: "View Ground Station Sites",
  //       href: Pages.GsSites + location.search,
  //     },
  //   ],
  // },
];
