export enum QueryKeys {
  APPROVAL_LIST = 'approvals',
  GROUND_STATION_LIST = 'groundstationList',
  CONTACT_LIST = 'contacts',
  VISUALIZATION_URLS = 'visualizationUrls',
  CONTACT_INFORMATION = 'contactInformation',
  CONTACT_DATAFLOWS = 'contactDataflows',
  POST_PASS_VISUALIZATIONS = 'postPassVisualizations',
  CONTACT_METADATA = 'contactMetaData',
  APP_SETTINGS = 'appSettings',
  MIDWAY = 'midway',
  PNG_IMAGE = 'pngImage',
  LIST_ROLES = 'listRoles',
  ANTENNAS = 'antennas',
  GSOC_CREDENTIALS = 'gsocCredentials',
}
