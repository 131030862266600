// Promise polyfill
import 'core-js/features/promise';

import '@amzn/awsui-global-styles/polaris.css';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { createRoot } from 'react-dom/client';
import { createPortal } from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import App from './App';
import CommonHeader from './components/CommonHeader';
import { InstrumentationProvider } from './context/InstrumentationContext';
import { NotificationsProvider } from './context/NotificationsContext';
import { assertDefined } from './utils/assertions';
import { AppSettingsProvider } from './context/AppSettingsContext';
import { MidwayProvider } from './context/MidwayContext';
import { ControlPlaneClientManagerProvider } from './context/ControlPlaneClientManagerContext';
import { GSOCApiProvider } from './context/GSOCApiContext';
import { AnalyticsAccountS3ClientProvider } from './context/AnalyticsAccountS3ClientContext';
import { LegacyAnalyticsAccountS3ClientProvider } from './context/LegacyAnalyticsAccountS3ClientContext';
import { LicenseExpiryDDBClientProvider } from './context/LicenseExpiryDDBClientContext';

// Render main application to #app
createRoot(assertDefined(document.querySelector('#app'))).render(
  <BrowserRouter>
    <NotificationsProvider>
      <QueryClientProvider client={new QueryClient()}>
        <AppSettingsProvider>
          <MidwayProvider>
            <GSOCApiProvider>
              <AnalyticsAccountS3ClientProvider>
                <LegacyAnalyticsAccountS3ClientProvider>
                  <ControlPlaneClientManagerProvider>
                    <LicenseExpiryDDBClientProvider>
                      <InstrumentationProvider>
                        {/* Render header to #h via a portal */}
                        {createPortal(<CommonHeader />, assertDefined(document.querySelector('#h')))}
                        <App />
                      </InstrumentationProvider>
                    </LicenseExpiryDDBClientProvider>
                  </ControlPlaneClientManagerProvider>
                </LegacyAnalyticsAccountS3ClientProvider>
              </AnalyticsAccountS3ClientProvider>
            </GSOCApiProvider>
          </MidwayProvider>
        </AppSettingsProvider>
      </QueryClientProvider>
    </NotificationsProvider>
  </BrowserRouter>,
);
