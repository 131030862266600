import { TopNavigation } from '@amzn/awsui-components-react';
import { useAppSettings } from '../context/AppSettingsContext';
import { LOGO_BASE_64 } from '../assets/gs_icons/logo';
import { NAV_I18NSTRINGS } from '../constants/i18nStrings';
import '../styles/top-navigation.scss';
import { accountsHelper } from '../utils/accountsUtils';

/**
 * Common header for all pages
 */
export default function CommonHeader() {
  const { stage } = useAppSettings();

  const regionSelectionItems = accountsHelper.getAccounts('opsconsole', stage).map(account => {
    const endpoint = accountsHelper
      .getAccount('opsconsole', stage, account.region)
      ?.services.find(service => service.service_key === 'gsoc')?.endpoint;
    if (endpoint === undefined) {
      throw new Error('Unable to get gsoc account');
    }
    return {
      id: account.region_abbr,
      text: account.region,
      href: `https://${endpoint}/#`,
    };
  });

  return (
    <TopNavigation
      identity={{
        href: '#',
        title: 'AWS Ground Station - Operations Console (GSOC)',
        logo: {
          src: LOGO_BASE_64,
          alt: 'GSOC',
        },
      }}
      utilities={[
        {
          type: 'button',
          badge: false,
          text: 'Cut GSOC SIM',
          href: 'https://issues.amazon.com/issues/create?template=0fcdeef1-2dde-4426-98ae-8811e0aed369',
          variant: 'link',
        },
        {
          type: 'menu-dropdown',
          text: 'Region Selection',
          description: 'Select Ops Console Region',
          items: regionSelectionItems,
        },
      ]}
      i18nStrings={NAV_I18NSTRINGS}
    />
  );
}
