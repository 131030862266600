import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { Spinner } from '@amzn/awsui-components-react';
import { fetchMidwayToken } from '../datafetchers/fetchMidwayToken';
import { QueryKeys } from '../constants/queryKeys';

const TIMEOUT_BUFFER_SECONDS = 30;
const RETRY_ATTEMPTS = 3;

export const MidwayContext = React.createContext<Awaited<ReturnType<typeof fetchMidwayToken>> | null>(null);

/**
 * Midway context.
 *
 * Used to fetch and provide a midway auth token to the application.
 *
 * @param children Nested components
 * @returns
 */
export function MidwayProvider(props: { children: React.ReactNode }) {
  const midwayQuery = useQuery({
    queryKey: [QueryKeys.MIDWAY],
    queryFn: ({ signal }) => fetchMidwayToken({ signal }),
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: RETRY_ATTEMPTS,
    onError: console.error,
  });

  const { data, refetch } = midwayQuery;
  const { decodedToken } = data ?? {};

  /** Refetch midway token just before it expires */
  React.useEffect(() => {
    if (decodedToken !== undefined) {
      const expiration = decodedToken.exp;
      const timeoutDuration = ((expiration * 1000 - Date.now()) / 1000 - TIMEOUT_BUFFER_SECONDS) * 1000;
      const timeoutHandle = window.setTimeout(() => {
        refetch().catch(console.error);
      }, timeoutDuration);

      return () => window.clearTimeout(timeoutHandle);
    }
  }, [decodedToken, refetch]);

  return (
    <MidwayContext.Provider value={midwayQuery.data ?? null}>
      {midwayQuery.data === undefined ? <Spinner size='large' /> : props.children}
    </MidwayContext.Provider>
  );
}

/** Use the midway token from the Midway context */
export function useMidwayToken(): string {
  const context = React.useContext(MidwayContext);
  if (context === null) {
    throw new Error('useMidwayToken must be used within a MidwayProvider');
  }
  return context.token;
}

/** Use the username from the Midway context */
export function useUsername(): string {
  const context = React.useContext(MidwayContext);
  if (context === null) {
    throw new Error('useUsername must be used within a MidwayProvider');
  }
  return context.decodedToken.sub;
}
