import type { S3 } from '@aws-sdk/client-s3';
import * as React from 'react';
import { S3WithErrorRefinement } from '../utils/s3';
import { buildTemporaryCredentialProvider } from '../clients/gsocCredentialsProvider';
import { ANALYTICS_NAME_PART, accountsHelper } from '../utils/accountsUtils';
import { DATA_ANALYTICS_READ_ONLY_ROLE_NAME, getRoleArn } from '../utils/gsRoleUtils';
import { useAppSettings } from './AppSettingsContext';
import { useGSOCReadOnlyCredentials } from './GSOCApiContext';
import { useUsername } from './MidwayContext';

export const AnalyticsAccountS3ClientContext = React.createContext<S3 | null>(null);

/**
 * S3Client context.
 *
 * Used to create and provide a, S3Client to the application.
 *
 * @param children Nested components
 * @returns
 */
export function AnalyticsAccountS3ClientProvider(props: { children: React.ReactNode }) {
  const { region, stage } = useAppSettings();
  const username = useUsername();
  const gsocReadonlyCredentials = useGSOCReadOnlyCredentials();

  const analyticsAccountS3Client = React.useMemo(() => {
    const analyticAccount = accountsHelper.getAccount(ANALYTICS_NAME_PART, stage, region);
    if (!analyticAccount) {
      throw new Error(`No analytics account found for stage ${stage} and region ${region}.`);
    }
    const analyticAccountId = analyticAccount.account_id;
    const roleArn = getRoleArn(analyticAccountId, DATA_ANALYTICS_READ_ONLY_ROLE_NAME);

    return new S3WithErrorRefinement({
      credentials: buildTemporaryCredentialProvider({
        roleArn,
        username,
        masterCredentials: {
          accessKeyId: gsocReadonlyCredentials.accessKeyId,
          secretAccessKey: gsocReadonlyCredentials.secretAccessKey,
          sessionToken: gsocReadonlyCredentials.sessionToken,
          expiration: gsocReadonlyCredentials.expiration,
        },
      }),
      region,
    });
  }, [gsocReadonlyCredentials, region, stage, username]);

  return (
    <AnalyticsAccountS3ClientContext.Provider value={analyticsAccountS3Client}>
      {props.children}
    </AnalyticsAccountS3ClientContext.Provider>
  );
}

/** Get the Analytics Account S3 client */
export function useAnalyticsAccountS3Client(): S3 {
  const context = React.useContext(AnalyticsAccountS3ClientContext);
  if (context === null) {
    throw new Error('useS3Client must be used within a AnalyticsAccountS3ClientProvider');
  }
  return context;
}
