import type { AwsRum } from 'aws-rum-web';
import * as React from 'react';
import { createRumClient } from '../clients/rumClient';
import { useAppSettings } from './AppSettingsContext';
import { useUsername } from './MidwayContext';

export interface IInstrumentation {
  rumClient: AwsRum;
}

export const InstrumentationContext = React.createContext<IInstrumentation | null>(null);

/**
 * Instrumentation context.
 *
 * Used to provide instrumentation to the application.
 *
 * @param children Nested components
 * @returns
 */
export function InstrumentationProvider(props: { children: React.ReactNode }) {
  const username = useUsername();
  const appSettings = useAppSettings();

  const providedValue = React.useMemo(() => {
    if (appSettings.rumApplicationId !== undefined && appSettings.rumRoleArn !== undefined) {
      const rumClient = createRumClient({
        user: username,
        applicationId: appSettings.rumApplicationId,
        region: appSettings.region,
        roleArn: appSettings.rumRoleArn,
      });

      return { rumClient };
    }
    return null;
  }, [appSettings.region, appSettings.rumApplicationId, appSettings.rumRoleArn, username]);

  return <InstrumentationContext.Provider value={providedValue}>{props.children}</InstrumentationContext.Provider>;
}

/**
 * Use the InstrumentationContext
 * @returns IInstrumentation
 */
export function useInstrumentation(): IInstrumentation {
  const context = React.useContext(InstrumentationContext);
  if (context === null) {
    throw new Error('useInstrumentation must be used within a InstrumentationContext');
  }
  return context;
}
