import * as React from 'react';
import { ControlPlaneClientManager } from '../clients/ControlPlaneClientManager';
import { useAppSettings } from './AppSettingsContext';
import { useGSOCReadOnlyCredentials } from './GSOCApiContext';
import { useUsername } from './MidwayContext';

export const ControlPlaneClientManagerContext = React.createContext<ControlPlaneClientManager | null>(null);

/**
 * ControlPlaneClientManager context.
 *
 * Used to create and provide a ControlPlaneClientManager to the application.
 *
 * @param children Nested components
 * @returns
 */
export function ControlPlaneClientManagerProvider(props: { children: React.ReactNode }) {
  const username = useUsername();
  const { region, stage } = useAppSettings();
  const gsocReadonlyCredentials = useGSOCReadOnlyCredentials();

  const controlPlaneClientManager = React.useMemo(
    () =>
      new ControlPlaneClientManager({
        credentials: gsocReadonlyCredentials,
        gsocRegion: region,
        stage,
        username,
      }),
    [gsocReadonlyCredentials, region, stage, username],
  );

  return (
    <ControlPlaneClientManagerContext.Provider value={controlPlaneClientManager}>
      {props.children}
    </ControlPlaneClientManagerContext.Provider>
  );
}

/**
 * Use the ControlPlaneClientManager
 * @returns ControlPlaneClientManager
 */
export function useControlPlaneClientManager(): ControlPlaneClientManager {
  const context = React.useContext(ControlPlaneClientManagerContext);
  if (context === null) {
    throw new Error('useControlPlaneClientManager must be used within a ControlPlaneClientManagerProvider');
  }
  return context;
}
