import { Spinner } from '@amzn/awsui-components-react';
import { useQuery } from '@tanstack/react-query';
import * as React from 'react';
import { QueryKeys } from '../constants/queryKeys';

export interface AppSettings {
  apiUrl: string;
  stage: string;
  region: string;
  accountId: string;
  rumApplicationId?: string;
  rumRoleArn?: string;
}

const RETRY_ATTEMPTS = 3;

export const AppSettingsContext = React.createContext<AppSettings | null>(null);

/**
 * AppSettungs context.
 *
 * Used to load and provide settings to the application.
 *
 * @param children Nested components
 * @returns
 */
export function AppSettingsProvider(props: { children: React.ReactNode }) {
  const appSettingsQuery = useQuery({
    queryKey: [QueryKeys.APP_SETTINGS],
    queryFn: async ({ signal }) => {
      const response = await fetch(`${globalThis.location.origin}/settings.json`, { signal });
      return (await response.json()) as AppSettings;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: RETRY_ATTEMPTS,
    onError: err => {
      console.error('Unable to load application settings', err);
    },
  });

  return (
    <AppSettingsContext.Provider value={appSettingsQuery.data ?? null}>
      {appSettingsQuery.data === undefined ? <Spinner size='large' /> : props.children}
    </AppSettingsContext.Provider>
  );
}

/**
 * Use the AppSettingsContext
 * @returns AppSettings
 */
export function useAppSettings(): AppSettings {
  const context = React.useContext(AppSettingsContext);
  if (context === null) {
    throw new Error('useAppSettings must be used within a AppSettingsProvider');
  }
  return context;
}
