import type {
  ContactDataInternal,
  DescribeContactInternalCommandOutput,
  GroundStationDataInternalListEntry,
  GroundStationType,
} from '@amzn/aws-gsaas-control-plane-internal-client';
import { GroundStationInternal } from '@amzn/aws-gsaas-control-plane-internal-client';
// eslint-disable-next-line import/no-unresolved
import * as NORAD_NAMES from 'norad_names.json';
import type { PartitionHash, RegionHash } from '@aws-sdk/config-resolver';
import { getRegionInfo } from '@aws-sdk/config-resolver';
import type { AwsCredentialIdentity, RegionInfoProviderOptions } from '@aws-sdk/types';
import { assertDefined } from '../utils/assertions';
import { accountsHelper } from '../utils/accountsUtils';
import { COMMON_CFN_READ_ONLY_ROLE_NAME, getRoleArn } from '../utils/gsRoleUtils';
import { buildTemporaryCredentialProvider } from './gsocCredentialsProvider';

const ALPHA_BETA_REGION_OVERRIDES = new Map<string, string>([['us-east-1', 'us-east-2']]);

export interface ContactData extends ContactDataInternal {
  internalCustomerAccountName?: string;
  internalExternal: string;
  noradName?: string;
}

/**
 * A light wrapper over the Ground Station Internal client
 *
 * Maintains an internal client instance for each region (lazily created) to prevent recreating every time we need to make a call.
 * Also does parameter currying so we don't have to carry around things like region and stage in every api call.
 */
export class ControlPlaneClientManager {
  private readonly groundStationInternalClientRegionMap = new Map<string, GroundStationInternal>();
  private readonly gsocRegion: string;
  private readonly stage: string;
  private readonly username: string;
  private readonly credentials: AwsCredentialIdentity;

  public constructor(props: {
    gsocRegion: string;
    username: string;
    stage: string;
    credentials: AwsCredentialIdentity;
  }) {
    this.gsocRegion = props.gsocRegion;
    this.stage = props.stage;
    this.username = props.username;
    this.credentials = props.credentials;
  }

  public async loadContactDetail(contactId: string): Promise<DescribeContactInternalCommandOutput> {
    return this.getGroundStationInternalClientForRegion(this.gsocRegion).describeContactInternal({
      contactId,
    });
  }

  public async loadContacts(props: {
    groundStation: GroundStationDataInternalListEntry;
    startTime: Date;
    endTime: Date;
  }): Promise<ContactData[]> {
    let nextToken: string | undefined;
    const contactList: ContactData[] = [];

    const groundStationInternalClient = this.getGroundStationInternalClientForRegion(
      // TODO: Determine if this is a necessary fallback and remove if not
      props.groundStation.region ?? this.gsocRegion,
    );
    do {
      const res = await groundStationInternalClient.listContactsInternal({
        startTime: props.startTime,
        endTime: props.endTime,
        groundStation: props.groundStation.groundStationId,
        nextToken,
      });

      contactList.push(
        ...(res.contactList?.map(c => ControlPlaneClientManager.mapContactDataInternal2External(c)) ?? []),
      );
      nextToken = res.nextToken;
    } while (nextToken != null);

    return contactList;
  }

  public async loadGroundStations() {
    let nextToken: string | undefined;
    const groundStations: GroundStationDataInternalListEntry[] = [];

    do {
      const response = await this.getGroundStationInternalClientForRegion(this.gsocRegion).internalListGroundStations({
        nextToken: nextToken,
      });
      groundStations.push(
        ...(response.groundStationList?.filter(gs =>
          ControlPlaneClientManager.isGroundStationTypeProduction(gs.type),
        ) ?? []),
      );
      nextToken = response.nextToken;
    } while (nextToken !== undefined);

    return groundStations;
  }

  private getGroundStationInternalClientForRegion(region: string): GroundStationInternal {
    const localizedClient = this.groundStationInternalClientRegionMap.get(region);
    if (localizedClient !== undefined) return localizedClient;

    // Create new client, cache it, return it, and set a timeout to refresh it later
    const newClient = ControlPlaneClientManager.buildGroundStationInternalClientForRegion({
      region,
      stage: this.stage,
      username: this.username,
      credentials: this.credentials,
    });
    this.groundStationInternalClientRegionMap.set(region, newClient);

    return newClient;
  }

  private static buildGroundStationInternalClientForRegion(opts: {
    region: string;
    stage: string;
    username: string;
    credentials: AwsCredentialIdentity;
  }) {
    const inAlphaOrBeta = opts.stage.includes('alpha') || opts.stage.includes('beta');
    const gsInternalRegionOverride = inAlphaOrBeta
      ? ALPHA_BETA_REGION_OVERRIDES.get(opts.region) ?? opts.region
      : opts.region;

    console.debug(`Creating GS internal account for ${gsInternalRegionOverride} on stage ${opts.stage}`);

    const account = accountsHelper.getAccount('controlplane', opts.stage, gsInternalRegionOverride);
    if (account === undefined) {
      throw new Error(`Cannot locate internal controlplane account for ${opts.stage} and ${gsInternalRegionOverride}`);
    }
    const service = account.services.find(service => service.service_key === 'controlplaneinternal');
    if (service === undefined) {
      throw new Error(`Failed to locate controlplaneinternal for account ${account.account_id}`);
    }
    console.debug('Found endpoint: ', service.endpoint);

    const roleArn = getRoleArn(account.account_id, COMMON_CFN_READ_ONLY_ROLE_NAME);

    return new GroundStationInternal({
      credentials: buildTemporaryCredentialProvider({
        roleArn,
        username: opts.username,
        masterCredentials: opts.credentials,
      }),
      region: gsInternalRegionOverride,
      endpoint: `https://${service.endpoint}`,
      regionInfoProvider: customRegionInfoProvider,
    });
  }

  private static isGroundStationTypeProduction(
    type: GroundStationType | string | undefined,
  ): type is GroundStationType.PRODUCTION {
    return type !== undefined ? 'PRODUCTION'.localeCompare(type) === 0 : false;
  }

  private static mapContactDataInternal2External(contact: ContactDataInternal): ContactData {
    const customerId: string = assertDefined(contact.customerId);

    const internal = accountsHelper.accountIds.includes(customerId);
    const internalCustomerAccountName = internal ? accountsHelper.awsAccounts[customerId].name : undefined;

    const satelliteCatalogNumber = contact.satelliteCatalogNumber ?? 0;
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const noradName = NORAD_NAMES[satelliteCatalogNumber.toString() as keyof typeof NORAD_NAMES] ?? 'UNKNOWN';
    return {
      internalExternal: internalCustomerAccountName !== undefined ? 'INTERNAL' : 'EXTERNAL',
      internalCustomerAccountName,
      noradName,
      ...contact,
    };
  }
}

function customRegionInfoProvider(region: string, options?: RegionInfoProviderOptions) {
  return Promise.resolve(
    getRegionInfo(region, {
      ...options,
      signingService: 'execute-api',
      regionHash,
      partitionHash,
    }),
  );
}

const regionHash: RegionHash = {
  'us-east-1': {
    variants: [
      {
        hostname: 'groundstation-fips.us-east-1.amazonaws.com',
        tags: ['fips'],
      },
    ],
  },
  'us-east-2': {
    variants: [
      {
        hostname: 'groundstation-fips.us-east-2.amazonaws.com',
        tags: ['fips'],
      },
    ],
  },
  'us-west-2': {
    variants: [
      {
        hostname: 'groundstation-fips.us-west-2.amazonaws.com',
        tags: ['fips'],
      },
    ],
  },
};

const partitionHash: PartitionHash = {
  aws: {
    regions: [
      'af-south-1',
      'ap-east-1',
      'ap-northeast-1',
      'ap-northeast-2',
      'ap-northeast-3',
      'ap-south-1',
      'ap-southeast-1',
      'ap-southeast-2',
      'ap-southeast-3',
      'ca-central-1',
      'eu-central-1',
      'eu-north-1',
      'eu-south-1',
      'eu-west-1',
      'eu-west-2',
      'eu-west-3',
      'fips-us-east-1',
      'fips-us-east-2',
      'fips-us-west-2',
      'me-south-1',
      'sa-east-1',
      'us-east-1',
      'us-east-2',
      'us-west-1',
      'us-west-2',
    ],
    regionRegex: '^(us|eu|ap|sa|ca|me|af)\\-\\w+\\-\\d+$',
    variants: [
      {
        hostname: 'groundstation.{region}.amazonaws.com',
        tags: [],
      },
      {
        hostname: 'groundstation-fips.{region}.amazonaws.com',
        tags: ['fips'],
      },
      {
        hostname: 'groundstation-fips.{region}.api.aws',
        tags: ['dualstack', 'fips'],
      },
      {
        hostname: 'groundstation.{region}.api.aws',
        tags: ['dualstack'],
      },
    ],
  },
  'aws-cn': {
    regions: ['cn-north-1', 'cn-northwest-1'],
    regionRegex: '^cn\\-\\w+\\-\\d+$',
    variants: [
      {
        hostname: 'groundstation.{region}.amazonaws.com.cn',
        tags: [],
      },
      {
        hostname: 'groundstation-fips.{region}.amazonaws.com.cn',
        tags: ['fips'],
      },
      {
        hostname: 'groundstation-fips.{region}.api.amazonwebservices.com.cn',
        tags: ['dualstack', 'fips'],
      },
      {
        hostname: 'groundstation.{region}.api.amazonwebservices.com.cn',
        tags: ['dualstack'],
      },
    ],
  },
  'aws-iso': {
    regions: ['us-iso-east-1', 'us-iso-west-1'],
    regionRegex: '^us\\-iso\\-\\w+\\-\\d+$',
    variants: [
      {
        hostname: 'groundstation.{region}.c2s.ic.gov',
        tags: [],
      },
      {
        hostname: 'groundstation-fips.{region}.c2s.ic.gov',
        tags: ['fips'],
      },
    ],
  },
  'aws-iso-b': {
    regions: ['us-isob-east-1'],
    regionRegex: '^us\\-isob\\-\\w+\\-\\d+$',
    variants: [
      {
        hostname: 'groundstation.{region}.sc2s.sgov.gov',
        tags: [],
      },
      {
        hostname: 'groundstation-fips.{region}.sc2s.sgov.gov',
        tags: ['fips'],
      },
    ],
  },
  'aws-us-gov': {
    regions: ['us-gov-east-1', 'us-gov-west-1'],
    regionRegex: '^us\\-gov\\-\\w+\\-\\d+$',
    variants: [
      {
        hostname: 'groundstation.{region}.amazonaws.com',
        tags: [],
      },
      {
        hostname: 'groundstation-fips.{region}.amazonaws.com',
        tags: ['fips'],
      },
      {
        hostname: 'groundstation-fips.{region}.api.aws',
        tags: ['dualstack', 'fips'],
      },
      {
        hostname: 'groundstation.{region}.api.aws',
        tags: ['dualstack'],
      },
    ],
  },
};
