import * as React from 'react';

import type { FlashbarProps } from '@amzn/awsui-components-react';

export interface Notification {
  type: FlashbarProps.Type;
  header: string;
  message: string;
  id: string;
  dismiss: () => void;
}

const NotificationsContext = React.createContext<{
  addNotification: (args: { type: FlashbarProps.Type; header: string; message: string }) => void;
  notifications: Notification[];
} | null>(null);

/**
 * Notifications context.
 *
 * Used to provide a notifications api to the application.
 *
 * @param children Nested components
 * @returns
 */
export function NotificationsProvider(props: { children: React.ReactNode }) {
  const [notifications, setNotifications] = React.useState<Notification[]>([]);
  const notificationId = React.useRef(0);

  const providedValue = React.useMemo(() => {
    const addNotification = (args: { type: FlashbarProps.Type; header: string; message: string }) => {
      const id = notificationId.current++;

      const dismiss = () =>
        setNotifications(previousNotifications =>
          previousNotifications.filter(notification => notification.id !== `${id}`),
        );

      setNotifications(previousNotifications => [
        ...previousNotifications,
        {
          ...args,
          id: `${id}`,
          dismiss,
        },
      ]);
    };
    return { addNotification, notifications };
  }, [notifications]);

  return <NotificationsContext.Provider value={providedValue}>{props.children}</NotificationsContext.Provider>;
}

export function useNotifications() {
  const context = React.useContext(NotificationsContext);
  if (context === null) {
    throw new Error('useNotifications must be used within a NotificationsContext');
  }
  return context;
}
