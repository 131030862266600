import * as AWS_ACCOUNTS from '@amzn/aws-gsaas-accounts-helper/dist/configuration/aws_gsaas_aws_accounts_config/aws_accounts.json';
import { AccountHelper } from '@amzn/aws-gsaas-accounts-helper';

export const accountsHelper = new AccountHelper(undefined, AWS_ACCOUNTS);

export const GSOC_NAME_PART = 'opsconsole';
export const ANALYTICS_NAME_PART = 'analytics';
export const CANARY_NAME_PART = 'canary';
export const CONTROLPLANE_DDB_NAME_PART = 'controlplaneddb';

export enum ACCOUNT_TYPE {
  Internal = 'INTERNAL',
  External = 'EXTERNAL',
  Canary = 'CANARY',
}

export const INTERNAL_ACCOUNT = 'INTERNAL';
export const EXTERNAL_ACCOUNT = 'EXTERNAL';
export const CANARY_ACCOUNT = 'CANARY';

export function getAccountNamePart(accountId: string): string {
  if (accountsHelper.accountIds.includes(accountId)) {
    return accountsHelper.awsAccounts[accountId].name_part;
  } else {
    return ACCOUNT_TYPE.External;
  }
}

export function getAccountType(namePart: string): ACCOUNT_TYPE {
  if (namePart === EXTERNAL_ACCOUNT) {
    return ACCOUNT_TYPE.External;
  }
  if (namePart.includes(CANARY_NAME_PART)) {
    return ACCOUNT_TYPE.Canary;
  }
  return ACCOUNT_TYPE.Internal;
}
