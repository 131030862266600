import { DefaultApi as GSOCApi } from '@amzn/aws-gsaas-gsoc-backend-typescript-client';
import axios from 'axios';
import * as React from 'react';
import type { AwsCredentialIdentity } from '@aws-sdk/types';
import { useQuery } from '@tanstack/react-query';
import { Spinner } from '@amzn/awsui-components-react';
import { READ_ONLY_GSOC_ROLE_NAME } from '../clients/gsocCredentialsProvider';
import { QueryKeys } from '../constants/queryKeys';
import { useMidwayToken } from './MidwayContext';
import { useAppSettings } from './AppSettingsContext';
import { useNotifications } from './NotificationsContext';

const TIMEOUT_BUFFER_SECONDS = 30;
const RETRY_ATTEMPTS = 3;

// Convenience export for GSOCApi
export { DefaultApi as GSOCApi } from '@amzn/aws-gsaas-gsoc-backend-typescript-client/';

export const GSOCApiContext = React.createContext<{
  client: GSOCApi;
  readOnlyCredentials: Required<AwsCredentialIdentity>;
} | null>(null);

/**
 * GSOCApi context.
 *
 * Used to create and provide a GSOCApi client to the application.
 *
 * @param children Nested components
 * @returns
 */
export function GSOCApiProvider(props: { children: React.ReactNode }) {
  const { addNotification } = useNotifications();
  const { apiUrl } = useAppSettings();
  const midwayToken = useMidwayToken();
  /**
   * Add Authorization header to all axios calls for backend API
   *
   * NOTE: if this is called multiple times without ejecting the interceptor,
   * it will add multiple of the same interceptors This caused issues after
   * the client was made several times. Here, we use the useEffect cleanup
   * return function to deregister the interceptor
   */
  React.useEffect(() => {
    const interceptorId = axios.interceptors.request.use(config => {
      config.headers.Authorization = `Bearer ${midwayToken}`;
      return config;
    });

    return () => axios.interceptors.request.eject(interceptorId);
  }, [midwayToken]);

  const gsocApiClient = React.useMemo(() => new GSOCApi(undefined, `${apiUrl}/jwt/v1`), [apiUrl]);

  /** Fetch ReadOnly credentials */
  const gsocReadonlyCredentialsQuery = useQuery({
    queryKey: [QueryKeys.GSOC_CREDENTIALS, { role: READ_ONLY_GSOC_ROLE_NAME, client: gsocApiClient }],
    queryFn: async ({ queryKey }) => {
      const [_, variables] = queryKey as [string, { role: string; client: GSOCApi }];
      const response = await variables.client.getCredentials(variables.role);
      return {
        accessKeyId: response.data.credentials.accessKeyId,
        secretAccessKey: response.data.credentials.secretAccessKey,
        sessionToken: response.data.credentials.sessionToken,
        expiration: new Date(response.data.credentials.expiration),
      } as Required<AwsCredentialIdentity>;
    },
    refetchOnWindowFocus: false,
    refetchOnMount: false,
    refetchOnReconnect: false,
    retry: RETRY_ATTEMPTS,
    onError: err => {
      console.error(`Error getting credentials for role: ${READ_ONLY_GSOC_ROLE_NAME}`, err);
      addNotification({
        type: 'error',
        header: `Error getting credentials for role: ${READ_ONLY_GSOC_ROLE_NAME}`,
        message: (err as Error).message,
      });
    },
  });

  const { data: gsocReadonlyCredentials, refetch } = gsocReadonlyCredentialsQuery;

  /** Refetch gsoc readonly credentials just before they expire */
  React.useEffect(() => {
    if (gsocReadonlyCredentials !== undefined) {
      const timeoutDuration =
        ((gsocReadonlyCredentials.expiration.getTime() - Date.now()) / 1000 - TIMEOUT_BUFFER_SECONDS) * 1000;
      const timeoutHandle = window.setTimeout(() => {
        refetch().catch(console.error);
      }, timeoutDuration);

      return () => window.clearTimeout(timeoutHandle);
    }
  }, [refetch, gsocReadonlyCredentials]);

  /** Memoize the context value so that it doesn't change on every render */
  const providedValue = React.useMemo(() => {
    if (gsocReadonlyCredentials === undefined) return null;
    return {
      client: gsocApiClient,
      readOnlyCredentials: gsocReadonlyCredentials,
    };
  }, [gsocApiClient, gsocReadonlyCredentials]);

  return (
    <GSOCApiContext.Provider value={providedValue}>
      {providedValue !== null ? props.children : <Spinner size='large' />}
    </GSOCApiContext.Provider>
  );
}

/**
 * Use the GSOCApi
 * @returns GSOCApi
 */
export function useGSOCApi(): GSOCApi {
  const context = React.useContext(GSOCApiContext);
  if (context === null) {
    throw new Error('useGSOCApi must be used within a GSOCApiProvider');
  }
  return context.client;
}

/**
 * Use GSOC ReadOnly Credentials
 * @returns Required<AwsCredentialIdentity>
 */
export function useGSOCReadOnlyCredentials(): Required<AwsCredentialIdentity> {
  const context = React.useContext(GSOCApiContext);
  if (context === null) {
    throw new Error('useGSOCReadOnlyCredentials must be used within a GSOCApiProvider');
  }
  return context.readOnlyCredentials;
}
