import type { S3 } from '@aws-sdk/client-s3';
import * as React from 'react';
import { S3WithErrorRefinement } from '../utils/s3';
import { buildTemporaryCredentialProvider } from '../clients/gsocCredentialsProvider';
import { GSOC_NAME_PART, accountsHelper } from '../utils/accountsUtils';
import { COMMON_CFN_READ_ONLY_ROLE_NAME, getRoleArn } from '../utils/gsRoleUtils';
import { useAppSettings } from './AppSettingsContext';
import { useGSOCReadOnlyCredentials } from './GSOCApiContext';
import { useUsername } from './MidwayContext';

export const LegacyAnalyticsAccountS3ClientContext = React.createContext<S3 | null>(null);

/**
 * S3Client context.
 *
 * Used to create and provide a, S3Client to the application.
 *
 * @param children Nested components
 * @returns
 */
export function LegacyAnalyticsAccountS3ClientProvider(props: { children: React.ReactNode }) {
  const { region, stage } = useAppSettings();
  const username = useUsername();
  const gsocReadonlyCredentials = useGSOCReadOnlyCredentials();

  const gsocAccountS3Client = React.useMemo(() => {
    const gsocAccount = accountsHelper.getAccount(GSOC_NAME_PART, stage, region);
    if (!gsocAccount) {
      throw new Error(`No gsoc account found for stage ${stage} and region ${region}.`);
    }
    const gsocAccountId = gsocAccount.account_id;
    const roleArn = getRoleArn(gsocAccountId, COMMON_CFN_READ_ONLY_ROLE_NAME);

    return new S3WithErrorRefinement({
      credentials: buildTemporaryCredentialProvider({
        roleArn,
        username,
        masterCredentials: {
          accessKeyId: gsocReadonlyCredentials.accessKeyId,
          secretAccessKey: gsocReadonlyCredentials.secretAccessKey,
          sessionToken: gsocReadonlyCredentials.sessionToken,
          expiration: gsocReadonlyCredentials.expiration,
        },
      }),
      region,
    });
  }, [gsocReadonlyCredentials, region, stage, username]);

  return (
    <LegacyAnalyticsAccountS3ClientContext.Provider value={gsocAccountS3Client}>
      {props.children}
    </LegacyAnalyticsAccountS3ClientContext.Provider>
  );
}

/** Get the Legacy Analytics S3 client in GSOC account */
export function useLegacyAnalyticsAccountS3Client(): S3 {
  const context = React.useContext(LegacyAnalyticsAccountS3ClientContext);
  if (context === null) {
    throw new Error('useS3Client must be used within a LegacyAnalyticsAccountS3ClientProvider');
  }
  return context;
}
