export type Pages = string;
/***
 * Define the pages and routing parameters needed.
 */
export const Pages = {
  HomePage: '/',
  Authorization: '/authorization',
  Contact: '/contact',
  ContactDetails: '/contact/:contactId',
  ContactList: '/contacts',
  Oncall: '/oncall',
  GsSites: '/sites',
  LicenseExpiry: '/licenses',
};

/**
 * Keeps our breadcrumbs for use elsewhere
 *
 * We have this as a class with only static methods because we can make them
 * getters and then will be re-evaluated on each access, which is important
 * for preserving the current location.search.
 */
// eslint-disable-next-line @typescript-eslint/no-extraneous-class
export class BREADCRUMBS {
  public static get Home() {
    return {
      text: 'Home',
      href: Pages.HomePage + location.search,
    };
  }

  public static get Oncall() {
    return {
      text: 'Oncall',
      href: Pages.Oncall + location.search,
    };
  }

  public static get Authorization() {
    return {
      text: 'Authorization',
      href: Pages.Authorization + location.search,
    };
  }

  public static get ListContacts() {
    return {
      text: 'List Contacts',
      href: Pages.ContactList + location.search,
    };
  }

  public static get Sites() {
    return {
      text: 'Sites',
      href: Pages.GsSites + location.search,
    };
  }

  public static get ContactSearch() {
    return {
      text: 'View Individual Contact',
      href: Pages.Contact + location.search,
    };
  }

  public static ContactDetails(contactId: string) {
    return {
      text: contactId,
      href: Pages.Contact + location.search,
    };
  }

  public static get LicenseExpiry() {
    return {
      text: 'License Expiry',
      href: Pages.LicenseExpiry + location.search,
    };
  }
}
