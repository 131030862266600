import * as React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { CommonAppLayout } from './components/CommonAppLayout';
import { BREADCRUMBS, Pages } from './pages/pages';

const GsSitesPage = React.lazy(() => import('./pages/GroundStationSitesPage'));
const ContactSearchPage = React.lazy(() => import('./pages/ContactSearchPage'));
const ContactDetailPage = React.lazy(() => import('./pages/ContactDetailsPage'));
const ContactListPage = React.lazy(() => import('./pages/ContactsListPage'));
const AuthorizationPage = React.lazy(() => import('./pages/AuthorizationPage'));
const OncallPage = React.lazy(() => import('./pages/OncallPage'));
const HomePage = React.lazy(() => import('./pages/HomePage'));
const LicenseExpiryPage = React.lazy(() => import('./pages/LicenseExpiryPage'));

// Note well: order of pages matters here.
export default function App() {
  return (
    <Switch>
      <Route path={Pages.GsSites}>
        <React.Suspense
          fallback={
            <CommonAppLayout navigationOpen={true} toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.Sites]} />
          }
        >
          <GsSitesPage />
        </React.Suspense>
      </Route>
      <Route exact path={Pages.Contact}>
        <React.Suspense
          fallback={
            <CommonAppLayout navigationOpen={true} breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.ContactSearch]} />
          }
        >
          <ContactSearchPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.ContactDetails}>
        <React.Suspense fallback={<CommonAppLayout breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.ContactSearch]} />}>
          <ContactDetailPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.ContactList}>
        <React.Suspense
          fallback={
            <CommonAppLayout navigationOpen={false} breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.ListContacts]} />
          }
        >
          <ContactListPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.Authorization}>
        <React.Suspense
          fallback={
            <CommonAppLayout
              navigationOpen={false}
              toolsHide
              breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.Authorization]}
            />
          }
        >
          <AuthorizationPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.Oncall}>
        <React.Suspense
          fallback={
            <CommonAppLayout navigationOpen={true} toolsHide breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.Oncall]} />
          }
        >
          <OncallPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.LicenseExpiry}>
        <React.Suspense
          fallback={
            <CommonAppLayout
              navigationOpen={true}
              toolsHide
              breadcrumbs={[BREADCRUMBS.Home, BREADCRUMBS.LicenseExpiry]}
            />
          }
        >
          <LicenseExpiryPage />
        </React.Suspense>
      </Route>
      <Route path={Pages.HomePage}>
        <React.Suspense fallback={<CommonAppLayout navigationOpen={true} toolsHide breadcrumbs={[BREADCRUMBS.Home]} />}>
          <HomePage />
        </React.Suspense>
      </Route>
      <Redirect to={Pages.HomePage} />
    </Switch>
  );
}
