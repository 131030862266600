import * as React from 'react';
import type { AppLayoutProps, BreadcrumbGroupProps } from '@amzn/awsui-components-react';
import { AppLayout, Box, BreadcrumbGroup, Flashbar, Toggle } from '@amzn/awsui-components-react';
import { applyMode, Mode } from '@amzn/awsui-global-styles';
import { ErrorBoundary } from 'react-error-boundary';
import { useHistory } from 'react-router-dom';
import { useNotifications } from '../context/NotificationsContext';
import { NavigationSidebar } from './NavigationSidebar';
import { ErrorDisplay } from './ErrorDisplay';

/**
 * CommonAppLayout allows the app to have some default settings for AppLayout
 * such as the navigation, hiding tools, breadcrumbs, and notifications
 */
export function CommonAppLayout(
  props: Omit<AppLayoutProps, 'breadcrumbs'> & {
    breadcrumbs: BreadcrumbGroupProps.Item[];
  },
) {
  const { notifications } = useNotifications();
  const [navigationOpen, setNavigationOpen] = React.useState(!!props.navigationOpen);
  const history = useHistory();

  const [darkMode, setDarkMode] = React.useState<boolean>(window.matchMedia('(prefers-color-scheme: dark)').matches);

  /** Sync dark mode with global styles */
  React.useEffect(() => {
    applyMode(darkMode ? Mode.Dark : Mode.Light);
  }, [darkMode]);

  return (
    <div style={{ marginTop: document.getElementById('h')?.offsetHeight ?? 56 }}>
      <AppLayout
        {...props}
        stickyNotifications
        navigation={
          <Box
            padding={{
              vertical: 's',
            }}
            margin={{
              left: 'm',
            }}
          >
            <Toggle onChange={({ detail }) => setDarkMode(detail.checked)} checked={darkMode}>
              Dark Mode
            </Toggle>
            <NavigationSidebar />
          </Box>
        }
        content={props.content}
        breadcrumbs={
          <BreadcrumbGroup
            items={props.breadcrumbs}
            expandAriaLabel='Show path'
            ariaLabel='Breadcrumbs'
            onFollow={ev => {
              ev.preventDefault();
              history.push(ev.detail.item.href);
            }}
          />
        }
        notifications={
          props.notifications ?? (
            <ErrorBoundary FallbackComponent={ErrorDisplay}>
              <Flashbar
                items={notifications.map(item => {
                  return {
                    header: item.header,
                    type: item.type,
                    content: item.message,
                    dismissible: true,
                    id: item.id,
                    onDismiss: item.dismiss,
                  };
                })}
              />
            </ErrorBoundary>
          )
        }
        toolsHide={props.tools === undefined}
        navigationOpen={navigationOpen}
        onNavigationChange={({ detail }) => setNavigationOpen(detail.open)}
      />
    </div>
  );
}
