import { fromTemporaryCredentials } from '@aws-sdk/credential-providers';
import type { AwsCredentialIdentity, AwsCredentialIdentityProvider } from '@aws-sdk/types';

export const READ_ONLY_GSOC_ROLE_NAME = 'AwsGsaasGSOCOpsReadOnly';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const READ_WRITE_GSOC_ROLE_NAME = 'AwsGsaasGSOCOpsReadWrite';

export function buildTemporaryCredentialProvider(opts: {
  roleArn: string;
  username: string;
  masterCredentials: AwsCredentialIdentity;
}): AwsCredentialIdentityProvider {
  // Will provide a ChainTemporaryCredentials
  // The masterCredentials will be used to assume the passed in roleArn.
  const sessionName = `${opts.username}-OpsConsole`;
  console.log(`Session name: ${sessionName}`);
  return fromTemporaryCredentials({
    params: {
      RoleArn: opts.roleArn,
      RoleSessionName: sessionName,
    },
    masterCredentials: opts.masterCredentials,
    // This client config must point to the STS global endpoint (us-east-1)
    // TODO: Regionalize the STS endpoint
    clientConfig: {
      endpoint: 'https://sts.us-east-1.amazonaws.com',
      region: 'us-east-1',
    },
  });
}
