import type { GetObjectCommandInput } from '@aws-sdk/client-s3';
import { S3 } from '@aws-sdk/client-s3';
import { readStreamToArrayBuffer } from './stream';

const UTF8_DECODER = new TextDecoder('utf8');

export class S3FileNotFound extends Error {}

export class S3AccessDenied extends Error {}

/** Simple subclass of S3 that intercepts NoSuchKey and throws S3FileNotFound */
export class S3WithErrorRefinement extends S3 {
  public async getObject(args: GetObjectCommandInput) {
    try {
      return await super.getObject(args);
    } catch (err) {
      if ((err as { Code?: string }).Code === 'NoSuchKey') {
        throw new S3FileNotFound(`Key: ${args.Key ?? ''} not found in Bucket: ${args.Bucket ?? ''}.`);
      } else if ((err as { Code?: string }).Code === 'AccessDenied') {
        throw new S3AccessDenied(`Access Denied: Key: ${args.Key ?? ''} in Bucket: ${args.Bucket ?? ''}.`);
      }

      throw new S3FileNotFound(`Unexpected Error. Key: ${args.Key ?? ''} not found in Bucket: ${args.Bucket ?? ''}.`);
    }
  }
}

/**
 * Fetches an S3 object and attempts to convert it via JSON.parse.
 *
 * Note: V8 has a string length limit of 512Mb, so json files whose
 * representation exceeds that will break at UTF8_DECODER.decode
 *
 * For those cases, a stream parser must be used
 * https://www.npmjs.com/package/stream-json is what we would want
 * to use for that. There is overhead associated with using a stream parser
 * that makes it inefficient for small files though.
 */
export async function jsonFromS3<T = unknown>(bucket: string, key: string, s3Client: S3): Promise<T> {
  const s3Object = await s3Client.getObject({ Bucket: bucket, Key: key });
  const readStream = s3Object.Body as ReadableStream<Uint8Array>;
  const arrayBuffer = await readStreamToArrayBuffer(readStream);
  const decodedString = UTF8_DECODER.decode(arrayBuffer);
  return JSON.parse(decodedString) as T;
}

/** Get an s3 object into a Blob */
export async function blobFromS3(bucket: string, key: string, s3Client: S3, blobType?: string): Promise<Blob> {
  const s3Object = await s3Client.getObject({ Bucket: bucket, Key: key });
  const readStream = s3Object.Body as ReadableStream<Uint8Array>;
  const arrayBuffer = await readStreamToArrayBuffer(readStream);
  const blob = new Blob([arrayBuffer], { type: blobType });
  return blob;
}
